.no_scroll {
  overflow: hidden;
}
.fw_600 {
  font-weight: 600 !important;
}
.pl0 {
  padding-left: 0px;
}
.pr0 {
  padding-right: 0px;
}
.p0 {
  padding: 0px;
}
.mb0 {
  margin-bottom: 0 !important;
}
.mb5 {
  margin-bottom: 5px;
}
.mb10 {
  margin-bottom: 10px;
}
.ml5 {
  margin-left: 5px;
}
.blur {
  filter: blur(8px);
  -webkit-filter: blur(8px);
  -o-filter: blur(8px);
  -moz-filter: blur(8px);
}
.potential_title {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  padding-top: 15px;
  color: #364347;
  background: white;
  top: -1px;
}

.potential_job_wrap {
  padding-top: 10px;
  .job_detail_wrap {
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 30px;
    letter-spacing: normal;
    text-align: center;
    color: #364347;
    padding-bottom: 10px;
  }
  .job_offer_title {
    font-size: 15px;
    font-weight: bold;
    line-height: 1.33;
    text-align: center;
    color: #93a4aa;
    padding-top: 10px;
  }

  .job_detail_title {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    margin-bottom: 4px;
  }

  .tow_title_wrap {
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #364347;
  }
  .job_offer_title {
    font-size: 15px;
    font-weight: bold;
    line-height: 1.33;
    text-align: center;
    color: #93a4aa;
    padding-top: 10px;
  }
}

.job_container_padding {
  padding-left: 20px;
  padding-right: 20px;
}

.dollar_wrap {
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #b2c6cd;
  margin-top: 2px;
  .active {
    color: #3eb5ac;
  }
}

.dollar_new_wrap {
  font-size: 50px;
  font-weight: 600;
  line-height: 0.83;
  letter-spacing: normal;
  text-align: center;
  color: #364347;

  .job_price {
    span {
      font-size: 24px;
      position: relative;
      bottom: 13px;
      font-weight: bold;
      left: 8px;
    }
  }
}

.miles_wrap {
  width: 100%;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #364347;
  margin: 19px 0px 15px 0px;
  padding: 9px 3px 7px 3px;
  .digit {
    font-size: 24px;
    font-weight: 600;
    line-height: 0.83;
    margin-bottom: 5px;
  }
  .text {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.25;
    letter-spacing: normal;
  }
}

.new_miles_wrap {
  padding-top: 0px !important;
  padding-bottom: 17px !important;
}

.h_line {
  height: 46px;
  width: 2px;
  background-color: #d6e1e5;
  padding: 0;
  position: relative;
  top: 6px;
}

.potential_job_main_wrap {
  font-family: "Source Sans Pro", sans-serif;
  .two_layer {
    display: flex;
    justify-content: space-between;
  }
  .two_layer.center {
    align-items: center;
  }
  .bottom_fix_wrapper {
    .close_icon_eta {
      position: absolute;
      right: 12px;
      top: 17px;
    }
  }
  .close_icon {
    display: inline !important;
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    img {
      position: relative;
      top: 13px;
    }
  }
}
.layer_wrap {
  border-top: 1px solid #d6e1e5;
  padding: 10px 15px 16px 0px;
  .left_wrap {
    color: #566164;
    font-weight: normal;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    margin-right: 15px;
    max-width: 350px;
    .light_title {
      font-size: 15px;
      font-weight: 600;
      color: #93a4aa;
      text-transform: uppercase;
    }
    .bold_title {
      font-weight: bold;
      color: #364347;
    }
    .blue_title {
      font-weight: 600;
      color: #02baf2;
    }
  }
  .right_wrap {
    img {
      margin-top: 5px;
    }
  }
}
.rsa_title {
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: normal;
  text-align: center;
  color: #364347;
  // margin: 32px 0px 32px 0px;
}
.commite_eta_wrap {
  border-radius: 4px;
  background-color: #f3f3f3;
}
.white_btn {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #364347;
  border-radius: 4px;
  border: solid 1px #d6e1e5;
  width: 100%;
  background: #fff;
  height: 50px;
  &:hover,
  &:active,
  &:focus {
    color: #364347;
    background: #fff;
  }
}
.contact_space {
  margin: 10px 0px 10px 0px;
}

.new_contact_space {
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  border-radius: 4px;
  width: 100%;
  background: #fff;
  height: 50px;
  font-family: "Source Sans Pro", sans-serif !important;
  margin: 10px 0px 10px 0px;
  font-size: 17px;
  border: 0px;
  color: #02baf2;
}

.canel_job_text {
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #93a4aa;
  width: 100%;
  padding: 10px 0px 10px 0px;
  margin-bottom: 20px;
  display: block;
  &:hover,
  &:active,
  &:focus {
    color: #93a4aa;
    text-decoration: none;
  }

  &.red_text_btn {
    color: #d32f2f;
  }

  &.blue_text_btn {
    color: #02baf2;
  }
}
.msg_overlay_wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  background-color: rgba(54, 67, 71, 0.8);
  .msg_overlay {
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    width: 100%;
    padding: 0px 10px;
    .main_title {
      font-size: 24px;
      font-weight: 600;
    }
  }
  .msg_loader {
    background-color: #364347;
    margin: 0 auto;
    margin-top: 21px;
    z-index: 2;
    position: relative;
    border-radius: 50px;
    width: 200px;
    height: 18px;
    .bar {
      background-color: #fff;
      z-index: 1;
      animation: bar_animation 5s ease-out infinite;
      position: relative;
      border-radius: 50px;
      width: 200px;
      height: 18px;
    }
  }
}
@keyframes bar_animation {
  from {
    width: 0px;
  }
  to {
    width: 200px;
  }
}
.bottom_btn_wrap {
  position: absolute;
  width: 100%;
  bottom: 72px;
  padding: 0px 20px;
}
.job_detail_bottom {
  width: 100%;
  height: calc(100vh - 150px);
  overflow: auto;
}
.potential_with_bottom {
  width: 100%;
  height: calc(100vh - 260px);
  overflow: auto;
}
.bottom_overlay {
  background-color: rgba(54, 67, 71, 0.2);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.bottom_fix_wrapper {
  width: 100%;
  height: 190px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  padding: 15px 20px 20px 20px;
  text-align: center;
  z-index: 99;
  p.title {
    font-size: 17px;
    color: #364347;
    font-weight: 600;
    margin-bottom: 20px;
    line-height: normal;
  }
  p.info {
    font-size: 16px;
    color: #5e696d;
    font-weight: normal;
    max-width: 80%;
    margin: 0 auto;
    margin-top: 15px;
    line-height: normal;
  }
  .btn_blue {
    width: 100%;
    height: 50px;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    color: #ffffff !important;
    border-radius: 4px;
    background-color: #02baf2 !important;
    margin-bottom: 10px;
    outline: 0;
    box-shadow: none;
    border: 1px solid #02baf2;
    text-transform: capitalize;
    &:hover,
    &:active,
    &:focus {
      color: #ffffff !important;
      border-radius: 4px;
      background-color: #02baf2 !important;
      outline: 0;
      box-shadow: none;
      border: 1px solid #02baf2;
    }
  }
  .btn_blank {
    width: 100%;
    height: 50px;
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    color: #02baf2 !important;
    border-radius: 4px;
    background-color: #ffffff !important;
    outline: 0;
    box-shadow: none;
    border: 1px solid #ffffff;
    text-transform: capitalize;
    margin-bottom: 0;
    &:hover,
    &:active,
    &:focus {
      color: #02baf2 !important;
      background-color: #ffffff !important;
      outline: 0;
      box-shadow: none;
      border: 1px solid #ffffff;
    }
  }
  .btn_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .btn_wrapper.eta {
    padding: 0 20px;
  }
  .btn_outline {
    width: 100%;
    height: 50px;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    color: #364347 !important;
    border-radius: 4px;
    background-color: #ffffff !important;
    outline: 0;
    box-shadow: none;
    border: 1px solid #d6e1e5;
    margin-right: 5px;
    text-transform: capitalize;
    &:hover,
    &:active,
    &:focus {
      color: #364347 !important;
      background-color: #ffffff !important;
      outline: 0;
      box-shadow: none;
      border: 1px solid #d6e1e5;
    }
  }
  .btn_red {
    width: 100%;
    height: 50px;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    color: #ffffff !important;
    border-radius: 4px;
    background-color: #f84545 !important;
    outline: 0;
    box-shadow: none;
    border: 1px solid #f84545;
    margin-left: 5px;
    text-transform: capitalize;
    &:hover,
    &:active,
    &:focus {
      color: #ffffff !important;
      background-color: #f84545 !important;
      outline: 0;
      box-shadow: none;
      border: 1px solid #f84545;
    }
  }
  .eta_form {
    width: 100%;
    .custom_control {
      width: 48%;
      margin-right: 5px;
      float: left;
      height: 50px;
      border: 1px solid #b2c6cd;
      font-size: 20px;
      font-weight: 400;
      color: #364347;
      outline: none;
      box-shadow: none;
      caret-color: #02baf2;
      text-align: left;
      &:hover,
      &:active,
      &:focus {
        border: 1px solid #b2c6cd;
        color: #364347;
        outline: none;
        box-shadow: none;
        caret-color: #02baf2;
      }
      &::placeholder {
        color: #b2c6cd;
      }
    }
    .btn_eta {
      width: 48%;
      float: left;
      text-transform: capitalize;
      &:hover,
      &:active,
      &:focus {
        width: 48%;
        float: left;
      }
    }
    .eta_group {
      display: flex;
      justify-content: center;
    }
  }
  .btn.disabled {
    opacity: 0.3;
  }
}
.bottom_fix_wrapper.cancel_decline {
  height: 160px;
}
.bottom_fix_wrapper.eta_popup {
  height: 190px;
}

.scheduled_pickup_wrap {
  border-radius: 6px;
  background-color: #f3f3f3;
  padding: 10px 8px;
  margin-bottom: 15px;
  display: flex;
  .schedule_text {
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #364347;
    margin-left: 7px;
  }
}

.gallery_wrap {
  padding-left: 15px;
  width: 100%;
}

.history_wrap {
  width: 100%;
  height: calc(100vh - 130px);
  overflow: auto;
}

.active_job_wrap {
  width: 100%;
  height: calc(100vh - 40px);
  overflow: auto;
}
.overlay {
  background-color: rgba(54, 67, 71, 0.1) !important;
}
